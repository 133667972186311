@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-stone-50 text-stone-600 subpixel-antialiased dark:bg-stone-950 dark:text-stone-400;
    }

    h1 {
        @apply font-expanded text-2xl font-bold text-stone-900 dark:text-stone-100;
    }

    h2 {
        @apply font-semi-expanded text-xl font-bold text-stone-900 dark:text-stone-100;
    }

    h3 {
        @apply text-lg font-bold text-stone-900 dark:text-stone-100;
    }

    em,
    i {
        @apply not-italic;

        font-variation-settings: 'slnt' -5;
    }
}

@layer components {
    .anchor-text,
    .button-link {
        @apply cursor-pointer font-semibold text-pink-700 underline decoration-pink-700/50 decoration-from-font transition-colors duration-300 hover:decoration-pink-700 dark:text-amber-400 dark:decoration-amber-400/50 dark:hover:decoration-amber-400;
    }

    .anchor-subtle {
        @apply cursor-pointer text-inherit underline decoration-zinc-400 decoration-from-font transition-colors hover:text-pink-600 hover:decoration-pink-600 dark:hover:text-amber-400 dark:hover:decoration-amber-400;
    }

    .card-clickable {
        @apply flex cursor-pointer flex-wrap gap-1 rounded border border-stone-100 bg-white p-4 text-left font-light shadow duration-300 ease-in-out hover:border-amber-400 hover:shadow-lg dark:border-stone-800 dark:bg-stone-900;
    }

    .card-flat {
        @apply flex flex-wrap gap-1 rounded border border-stone-100 bg-white p-4 text-left font-light dark:border-stone-900 dark:bg-white/[.04];
    }

    .button-blue {
        @apply inline-flex min-w-10 cursor-pointer items-center justify-center gap-2 rounded-lg border border-pink-300 bg-pink-600 px-6 py-3 text-lg font-semibold text-white shadow transition-all duration-300 hover:bg-pink-500 hover:shadow-lg disabled:cursor-default disabled:border-stone-200 disabled:bg-stone-200 disabled:shadow-none dark:border-pink-800 dark:disabled:border-stone-900 dark:disabled:bg-stone-900 dark:disabled:text-stone-800;
    }

    .button-outline {
        @apply inline-flex w-full min-w-10 cursor-pointer items-center justify-center gap-2 rounded-lg border border-stone-200 bg-transparent py-3 text-base font-semibold transition-colors hover:border-amber-400 dark:border-stone-700;
    }

    .button-light {
        @apply inline-flex min-w-10 cursor-pointer items-center justify-center gap-2 rounded-lg border border-stone-100 bg-white px-6 py-3 font-semibold shadow transition-all duration-300 hover:border-amber-400 hover:shadow-lg dark:border-stone-800 dark:bg-stone-900;
    }

    .button-animated {
        @apply inline-flex min-w-10 animate-gradient cursor-pointer items-center justify-center rounded bg-gradient-to-r from-purple-500 to-pink-500 bg-[size:200%_200%] px-6 py-3 text-sm font-semibold leading-snug text-white shadow transition-shadow hover:shadow-lg sm:text-base;
    }

    .button-tab {
        @apply inline-flex min-w-10 cursor-pointer items-center justify-center gap-2 rounded border-2 border-stone-200 bg-transparent px-6 py-3 text-lg font-normal transition-colors hover:border-amber-400 md:rounded-none md:border-l-0 md:border-r-0 md:border-t-0 dark:border-stone-700;
    }

    .button-active {
        @apply border-amber-400;
    }

    .button-navigation {
        @apply flex gap-2 rounded px-2 py-1 transition-colors duration-300 hover:bg-yellow-100 dark:hover:bg-yellow-100/20;
    }

    .iphone-container {
        @apply mx-auto my-4 flex h-max w-full max-w-96 flex-col gap-4 text-left sm:w-[400px] sm:border-b sm:border-stone-200 sm:bg-iphone sm:bg-top sm:bg-no-repeat sm:pb-8 sm:pt-16 sm:dark:border-stone-700;
    }

    .full-bleed {
        @apply relative inset-x-2/4 ml-[-50vw] mr-[-50vw] w-screen;
    }

    .ReactModal__Overlay--after-open {
        @apply opacity-100 !important;
    }

    .ReactModal__Overlay,
    .ReactModal__Overlay--before-close {
        @apply opacity-0;
    }
}

@layer utilities {
    .font-expanded {
        font-stretch: expanded;
    }

    .font-semi-expanded {
        font-stretch: semi-expanded;
    }

    .font-condensed {
        font-stretch: condensed;
    }

    .font-optical-expanded {
        font-variation-settings: 'opsz' 8;
    }

    .font-optical-italic {
        font-variation-settings: 'slnt' -5;
    }
}

@layer base {
    @font-face {
        font-family: 'Hubot Sans';
        src:
            url('/fonts/Hubot-Sans/font.woff2')
                format('woff2 supports variations'),
            url('/fonts/Hubot-Sans/font.woff2') format('woff2-variations');
        font-weight: 200 900;
        font-stretch: 75% 125%;
        font-display: swap;
        text-underline-position: from-font;
        font-synthesis: none;
        font-feature-settings: 'liga', 'ss01', 'tnum';
    }

    @font-face {
        font-family: 'Jet Brains Mono';
        src: url('/fonts/JetBrainsMono-Regular/font.woff2') format('woff2');
        font-weight: 400;
        font-display: swap;
    }

    @font-face {
        font-family: 'Source Serif';
        src: url('/fonts/SourceSerif/font.woff2') format('woff2');
        font-weight: 200 900;
        font-display: swap;
    }
}
