@import 'src/styles/vars';
@import 'src/styles/mixins';

/*
* Sass for http://propublica.github.io/stateface/ icon set.
* also adopted some code from other contributors:
*  - replacement: Paul Smith https://github.com/paulsmith
*  - FIPS state abbreviations: https://github.com/joetric
*/

@font-face {
    font-family: sf-regular;
    src: url('/fonts/StateFace/stateface-regular-webfont.eot');
    src:
        url('/fonts/StateFace/stateface-regular-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('/fonts/StateFace/stateface-regular-webfont.woff') format('woff'),
        url('/fonts/StateFace/stateface-regular-webfont.ttf') format('truetype'),
        url('/fonts/StateFace/stateface-regular-webfont.svg#sf-regular')
            format('svg');
    font-style: normal;
    font-weight: normal;
}

%state-face {
    &.sf-replace {
        position: relative;
        display: inline-block;
        min-width: 1em;
        text-indent: -999em;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            text-indent: 0;
        }
    }

    &::before {
        display: inline-block;
        margin-right: 5px;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: sf-regular;
        font-style: normal;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: middle;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizelegibility;
        text-transform: none;
        speak: none;

        @media (prefers-color-scheme: dark) {
            color: $dark-text-lightest;
        }
    }
}

/* Icons */
$state-face: ('alabama', 'al', '01', 'B'), ('alaska', 'ak', '02', 'A'),
    ('arizona', 'az', '04', 'D'), ('arkansas', 'ar', '05', 'C'),
    ('california', 'ca', '06', 'E'), ('colorado', 'co', '08', 'F'),
    ('connecticut', 'ct', '09', 'G'), ('delaware', 'de', '10', 'H'),
    ('florida', 'fl', '12', 'I'), ('georgia', 'ga', '13', 'J'),
    ('hawaii', 'hi', '15', 'K'), ('idaho', 'id', '16', 'M'),
    ('illinois', 'il', '17', 'N'), ('indiana', 'in', '18', 'O'),
    ('iowa', 'ia', '19', 'L'), ('kansas', 'ks', '20', 'P'),
    ('kentucky', 'ky', '21', 'Q'), ('louisiana', 'la', '22', 'R'),
    ('maine', 'me', '23', 'U'), ('maryland', 'md', '24', 'T'),
    ('massachusetts', 'ma', '25', 'S'), ('michigan', 'mi', '26', 'V'),
    ('minnesota', 'mn', '27', 'W'), ('mississippi', 'ms', '28', 'Y'),
    ('missouri', 'mo', '29', 'X'), ('montana', 'mt', '30', 'Z'),
    ('nebraska', 'ne', '31', 'c'), ('nevada', 'nv', '32', 'g'),
    ('new-hampshire', 'nh', '33', 'd'), ('new-jersey', 'nj', '34', 'e'),
    ('new-mexico', 'nm', '35', 'f'), ('new-york', 'ny', '36', 'h'),
    ('north-carolina', 'nc', '37', 'a'), ('north-dakota', 'nd', '38', 'b'),
    ('ohio', 'oh', '39', 'i'), ('oklahoma', 'ok', '40', 'j'),
    ('oregon', 'or', '41', 'k'), ('pennsylvania', 'pa', '42', 'l'),
    ('rhode-island', 'ri', '44', 'm'), ('south-carolina', 'sc', '45', 'n'),
    ('south-dakota', 'sd', '46', 'o'), ('tennessee', 'tn', '47', 'p'),
    ('texas', 'tx', '48', 'q'), ('utah', 'ut', '49', 'r'),
    ('vermont', 'vt', '50', 't'), ('virginia', 'va', '51', 's'),
    ('washington', 'wa', '53', 'u'), ('west-virginia', 'wv', '54', 'w'),
    ('wisconsin', 'wi', '55', 'v'), ('wyoming', 'wy', '56', 'x'),
    ('district-of-columbia', 'dc', '11001', 'y'),
    ('puerto-rico', 'pr', '72', '3'), ('guam', 'gu', '66', '4');

@each $i in $state-face {
    .sf-#{nth($i, 1)},
    .sf-#{nth($i, 2)},
    .sf-#{nth($i, 3)} {
        @extend %state-face;

        &::before {
            /* stylelint-disable-next-line scss/no-global-function-names */
            content: nth($i, 4);
        }
    }
}
