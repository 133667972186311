@use 'sass:color';

// System stacks

$font-system-sans:
    -apple-system,
    blinkmacsystemfont,
    segoe ui,
    helvetica,
    arial,
    sans-serif,
    apple color emoji,
    segoe ui emoji;
$font-system-serif: constantia, 'Lucida Bright', lucidabright, 'Lucida Serif',
    lucida, 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', georgia,
    serif;
$font-system-mono:
    sfmono-regular,
    consolas,
    liberation mono,
    menlo,
    monospace;

// Enhanced font stacks

$font-family-sans: 'Hubot Sans', $font-system-sans;
$font-family-mono: 'Jet Brains Mono', $font-system-mono;

// Font sizes

$font-size-micro: 0.5625rem;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-md-quote: 1.25rem;
$font-size-lg: 1.5rem;
$font-size-xl: 2.25rem;
$font-size-xxl: 3rem;

// Line heights

$line-height-xs: 1.125rem;
$line-height-sm: 1.5rem;
$line-height-md: 1.5rem;
$line-height-lg: 2.25rem;
$line-height-xl: 4.1.25rem;
$line-height-xxl: 3rem;

// Font weights

$sans-light: 350;
$sans-medium: 500;
$sans-bold: 800;
$serif-light: 300;
$serif-bold: 700;
$header-light: 300;
$header-bold: 700;

// New weights

$weight-light: 360;

// Typography

$max-line-length: 60ch;

// Palette

$black: rgb(16 24 32);
$white: #fff;
$color-blue-light: #0092ff;
$color-gray-lightest: rgba($black, 10%);
$color-gray-background: #e5e0d8;
$color-gray-light: #bbb;
$color-gray-dark: rgba($black, 0.66);
$color-purple: #9637e2;
$color-red: #c70d83;
$color-yellow: #ffc20e;
$coin-gold-light: #ffd635;
$coin-gold-dark: #d8a912;

// Semantic Colors

$color-gray-separator: rgba($color-gray-lightest, 0.1);
$cta-color: $color-red;
$background-color: rgb(247 244 243);
$shadow-color: color.adjust(
    $background-color,
    $lightness: -10%,
    $saturation: -10%
);
$heading-color: $black;
$text-color: $color-gray-dark;
$text-color-lightest: rgba($black, 0.6);
$link-color: $color-purple;
$link-hover-color: $color-blue-light;
$chat-bubble: $color-gray-lightest;

// Dark Mode

$dark-body: #2c2c30;
$dark-shadow-color: color.adjust($dark-body, $lightness: -5%, $saturation: -5%);
$dark-header-footer: #302954;
$dark-heading-color: #efe7df;
$dark-cta: $color-yellow;
$dark-text: rgba($white, 0.7);
$dark-text-lightest: rgba($white, 0.53);
$dark-separator: color.adjust($dark-body, $lightness: 10%);

// Buttons

$color-button-default: $white;
$color-button-hover: #80f1ff;
$color-button-purple: $color-purple;

// Dimensions

$radius-default: 4px;
$radius-bubble: 12px;
$radius-progress: $radius-bubble;
$height-button: 2.25rem;
$height-tabs: 2.25rem;
$single-column-width: 680px;
$min-touch-target: 44px;

// Z-Indexes

$z-index-low: 1;

// Breakpoints

/* Larger than iPhone 8+ portrait */
$breakpoint-mobile: 414px;

/* Larger than iPhone SE landscape (also point when grid becomes active) */
$breakpoint-phablet: 568px;

/* Larger than iPad mini portrait */
$breakpoint-tablet: 768px;

/* Larger than iPad landscape */
$breakpoint-desktop: 1024px;

/* Larger than Desktop HD */
$breakpoint-desktop-hd: 1366px;

// Transitions

$transition-default: 280ms cubic-bezier(0.4, 0, 0.2, 1);
