@use 'sass:color';
@import 'vars';

@mixin clear-float {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin single-column-text {
    margin: auto;
    max-width: $single-column-width;
}

@mixin full-bleed {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin italic($slant: -5) {
    font-variation-settings: 'slnt' $slant;
}

@mixin line-clamp($n) {
    display: -webkit-box;
    -webkit-line-clamp: $n;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin font-large {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-weight: $sans-medium;
}

@mixin font-normal {
    font-size: $font-size-md;
    line-height: $line-height-md;
    font-weight: $sans-light;
}

@mixin font-small {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $sans-medium;
}

@mixin font-smaller {
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $sans-medium;
}

@mixin small-caps {
    color: $text-color;
    font-family: $font-family-sans;
    font-size: $font-size-sm;
    font-stretch: 110%;
    font-weight: 400;
    line-height: $line-height-xs;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    @media (prefers-color-scheme: dark) {
        color: color.invert($text-color);
    }

    em {
        color: color.adjust($text-color, $lightness: 5%);
        text-transform: lowercase;

        @media (prefers-color-scheme: dark) {
            color: color.adjust(color.invert($text-color), $lightness: -5%);
        }
    }

    strong {
        font-weight: 550;
    }
}

@mixin heading {
    font-weight: $sans-bold;
    color: $heading-color;

    @media (prefers-color-scheme: dark) {
        color: $dark-heading-color;
    }
}

@mixin gradient-text {
    display: inline-block;
    background: linear-gradient(
        -45deg,
        rgb(255 0 187 / 100%),
        rgb(144 7 214 / 100%),
        rgb(0 161 255 / 100%)
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (prefers-color-scheme: dark) {
        background: linear-gradient(
            -45deg,
            rgb(255 0 229 / 100%),
            rgb(244 222 255 / 100%),
            rgb(0 224 255 / 100%)
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@mixin border-top($spacing: 0, $width: 1px) {
    border-top: $width solid $color-gray-separator;
    margin-top: calc(px($spacing) - $width);
    padding-top: $spacing;

    @media (prefers-color-scheme: dark) {
        border-color: $dark-separator;
    }
}

@mixin border-bottom($spacing: 0, $width: 1px) {
    border-bottom: $width solid $color-gray-separator;
    margin-bottom: calc($spacing - $width);
    padding-bottom: $spacing;

    @media (prefers-color-scheme: dark) {
        border-color: $dark-separator;
    }
}

@mixin subtle-link {
    color: inherit;
    text-decoration-color: rgba($text-color, 0.25);

    &:active,
    &:hover,
    &:focus {
        color: $cta-color;
        text-decoration-color: $cta-color;
    }

    @media (prefers-color-scheme: dark) {
        text-decoration-color: rgba($dark-text, 0.25);

        &:active,
        &:hover,
        &:focus {
            color: $dark-cta;
            text-decoration-color: $dark-cta;
        }
    }
}

@mixin filter-pill {
    @include small-caps;

    text-decoration: none;
    margin-right: 8px;
    border: 1px solid $color-gray-separator;
    padding: 3px 7px;
    border-radius: $radius-bubble;

    &:hover {
        border-color: $color-blue-light;
    }
}

@mixin crumbs {
    @include small-caps;

    list-style: none;
    margin: 0;

    li {
        color: $heading-color;
        display: inline;

        a,
        a::after {
            color: $text-color-lightest;
        }

        a {
            @include subtle-link;
        }

        &::after {
            content: '>';
            font-weight: $sans-light;
            margin: 0 8px;
        }

        &:last-of-type {
            &::after {
                content: '';
                margin-right: 0;
            }
        }

        @media (prefers-color-scheme: dark) {
            color: $dark-heading-color;

            a,
            &::after {
                color: $dark-text-lightest;
            }
        }
    }

    @media print {
        display: none;
    }
}

@mixin word-wrap {
    hyphens: auto;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
}

@mixin longform {
    @include word-wrap;

    color: $black;
    font-size: $font-size-md;
    line-height: $line-height-md;
    max-width: $max-line-length;

    @media (prefers-color-scheme: dark) {
        color: $white;
    }
}

@mixin dialog {
    border-radius: $radius-default;
    box-shadow:
        0 11px 15px -7px rgb(0 0 0 / 20%),
        0 24px 38px 3px rgb(0 0 0 / 14%),
        0 9px 46px 8px rgb(0 0 0 / 12%);
    transition:
        opacity,
        visibility $transition-default;
}

@mixin card-flat($borderWidth: 0, $borderColor: transparent) {
    background: $white;
    border-radius: $radius-default;
    box-shadow:
        inset 0 0 0.5px 1px hsl(0deg 0% 100% / 7.5%),
        0 0 0 1px hsl(0deg 0% 0% / 5%);
    text-decoration: none;
    border: $borderWidth solid $borderColor;

    @media (prefers-color-scheme: dark) {
        border-color: $borderColor;
        background: color.adjust($dark-body, $lightness: 5%);
    }
}

@mixin card {
    @include diffuse(2, $shadow-color, false);

    background: $white;
    border-radius: $radius-default;
    text-decoration: none;

    &:hover,
    &:focus {
        @include diffuse(8, $shadow-color, false);

        scale: 1.01;
    }

    @media (prefers-color-scheme: dark) {
        @include diffuse(2, $dark-shadow-color, false);

        background: color.adjust($dark-body, $lightness: 5%);

        &:hover,
        &:focus {
            @include diffuse(8, $dark-shadow-color, false);
        }
    }
}

@mixin button {
    border: none;
    border-radius: $radius-default;
    box-shadow:
        0 3px 1px -2px rgb(0 0 0 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
    text-decoration: none;
    transition: all $transition-default;

    &:hover,
    &:focus {
        box-shadow:
            0 2px 4px -1px rgb(0 0 0 / 20%),
            0 4px 5px 0 rgb(0 0 0 / 14%),
            0 1px 10px 0 rgb(0 0 0 / 12%);
    }
}

@mixin link() {
    color: $cta-color;
    font-weight: $sans-medium;
    text-decoration: underline;
    text-decoration-color: rgba($cta-color, 0.4);
    text-underline-position: from-font;
    transition: all $transition-default;

    &:hover {
        text-decoration-color: revert;
    }

    @media (prefers-color-scheme: dark) {
        color: $dark-cta;
        text-decoration-color: rgba($dark-cta, 0.4);

        &:hover {
            text-decoration-color: revert;
        }
    }
}

@mixin empty-block {
    @include card-flat;

    font-size: $font-size-md;
    line-height: $line-height-md;
    font-weight: $sans-light;
    text-align: center;
    padding: 8rem 4rem;
    margin-bottom: 0.625rem;

    a {
        font-weight: $sans-light;
    }
}

@mixin viewport-mobile {
    @media only screen and (min-width: $breakpoint-mobile) {
        @content;
    }
}

@mixin viewport-phablet {
    @media only screen and (min-width: $breakpoint-phablet) {
        @content;
    }
}

@mixin viewport-tablet {
    @media only screen and (min-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin viewport-desktop {
    @media only screen and (min-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin viewport-desktop-hd {
    @media only screen and (min-width: $breakpoint-desktop-hd) {
        @content;
    }
}

@mixin up-to-desktop {
    @media screen and (max-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin up-to-phablet {
    @media screen and (max-width: $breakpoint-phablet) {
        @content;
    }
}

@mixin up-to-tablet {
    @media screen and (max-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin invert-on-dark {
    @media (prefers-color-scheme: dark) {
        filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(309deg)
            brightness(105%) contrast(100%);
    }
}

@keyframes infinite-gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@function diffuse-shadows(
    $size,
    $color: rgba(0, 0, 0, 0.125),
    $multiply: false
) {
    $val: 0 1px 1px $color;
    @for $i from 1 through $size {
        @if $i % 2 == 0 {
            @if $multiply == true {
                $i: $i * 2;
            }

            $val:
                #{$val},
                0 #{$i}px #{$i}px #{$color};
        }
    }
    @return $val;
}

@mixin diffuse($size, $color, $multiply) {
    box-shadow: diffuse-shadows($size, $color, $multiply);
}
